@tailwind base;
@tailwind components;
@tailwind utilities;

.error {
  @apply mt-1 text-red;
}

.form-input {
  @apply h-[46px] rounded-lg border border-black bg-backgroundSolidGrey pl-2 text-xl font-normal text-navyBlue focus:outline-none;
}

.form-input:disabled,
select:disabled {
  background: #beced5;
  opacity: 100;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

.primary-field-container {
  @apply flex w-full flex-col xl:w-auto;
}

.form-label {
  @apply text-xl font-bold text-navyBlue xl:mb-3;
}
