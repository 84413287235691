@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-header-container {
  @apply mb-4 flex w-full items-center justify-center self-start rounded-3xl border-4 border-black bg-white p-3;
}

.page-header-text {
  @apply text-center text-2xl font-bold leading-7 text-navyBlue md:text-left md:text-[32px] md:leading-[38.73px];
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.DayPickerInput-Overlay {
  z-index: 9999 !important;
}

@media print {
  html,
  body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  #header {
    @apply hidden;
  }

  #header-buttons {
    @apply hidden;
  }
}

.BadgeTotalReviews__Content-sc-1ipdekn-0 {
  color: #017ead !important;
  font-weight: 700 !important;
  font-size: 17px !important;
  line-height: 20.57px !important;
}

.blue-icon {
  filter: invert(0%) sepia(100%) saturate(6874%) hue-rotate(198deg)
    brightness(94%) contrast(105%);
}
